.about {
    animation-name: FadeIn;
    animation-duration: 0.7s;
    transition-timing-function: ease-in;
    margin: 0;
    min-height: calc(100vh - 122px);
}

.about .page-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 2% 8%;
    padding: 3% 8%;
    background-color: white;
}

.about h3 {
    margin: 0;
    padding: 25px;
    text-align: center;
}

.about h4 h5 {
    font-weight: bold;
}

.about .text-groups p {
    margin-bottom: 30px;
}

.about .text-groups h5 {
    margin-bottom: 15px;
}

.about p{
    margin: 0;
}

.about .era-img {
    width: 80%;
    margin: auto;
    padding: 15px 0;
}

.about .scio-img {
    width: 10%;
    padding: 15px 0;
}

.about .text-with-link {
    display: inline-block;
}

.about .profile-card {
    display: flex;
    flex-direction: column;
}

.about .profile-card img {
    width: 150px;
    height: 150px;
    margin-bottom: 15px;
}

.about .profile-card p {
    margin-bottom: 30px;
}

.about .profile-card h5 {
    margin-bottom: 15px;
}

.about .profile-card a {
    margin-bottom: 5px;
}
