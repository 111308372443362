.menu-bar {
    background: linear-gradient(90deg, white 40%, #375a63 100% );
    margin: 0;
    position: relative;
}

.menu-bar .cell{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.menu-bar .cell p{
    cursor: pointer;
    color: #375a63;
    margin: 0;
    padding: 25px;
    transition: color 0.3s, background-color 0.3s;
}
.menu-bar .cell p:hover{
    transition: color 0.3s, background-color 0.3s;
    color: #f8c626;
    background-color: rgba(238, 238, 238, 0.4);
}
.menu-bar .cell img{
    width: 40px;
    cursor: pointer;
}
.menu-bar .logo{
    min-width: fit-content;
}
.menu-bar #login p{
    color: white;
    background-color: transparent;
}
.menu-bar #login .selection-group:hover *{
    transition: color 0.3s;
    color: #f8c626;
    background-color: transparent;
}
.menu-bar #login i{
    cursor: pointer;
    font-size: 25px;
    margin-right: 50px;
    color: white;
    background-color: transparent;
}
.menu-bar #login i:hover{
    transition: color 0.3s;
    color: #f8c626;
    background-color: transparent;
}

.menu-bar #login .card {
    position: absolute;
    top: 60px;
    right: 5px;
    z-index: 10;
    width: 120px;
    padding: 5px 0;
}
.menu-bar #login .card .selection-group{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.menu-bar #login .card p{
    color: #375a63;
    padding: 5px;
}
.menu-bar #login .card p:hover{
    color: #f8c626;
}
.menu-bar #login .card i{
    font-size: 15px;
    color: #375a63;
    margin: 5px;
}
.menu-bar #login .card i:hover{
    color: #f8c626;
}
.menu-bar .buttons{
    justify-content: flex-start;
}
.menu-bar .buttons .p-ink{
    background: #375a6330;
}
.menu-bar .bar{
    display: flex;
    height: 2px;
    background-color: #375a63;
    width: 100px;
    position: absolute;
    transition: position 0.6s
}

.menu-bar .right-menu {
    display: flex;
    flex-direction: row;
    gap: 30px;
    z-index: 1;
}

.navigation-prompt {
    width: 40vw;
}

.navigation-prompt .p-dialog-content {
    display: flex;
    gap: 12px;
    flex-direction: column;
}

.navigation-prompt .p-dialog-content p {
    margin: 0 !important;
    font-size: 16px;
}

.menu-bar .buttons {
    flex-grow: unset;
}

.menu-bar .right-menu {
    margin-left: auto;
}