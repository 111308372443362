.overlay {
	width: 100%;
	height: 100%;
	background-color: lightgrey;
	position: fixed;
	z-index: 10;
	display: flex;
	justify-content: space-between;
	align-items: center;
	opacity: 50%;
}