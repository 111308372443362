/*.edit-resource .bibliographic {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    animation-name: FadeIn;*/
/*    animation-duration: 0.7s;*/
/*    transition-timing-function: ease-in;*/
/*    margin: 0;*/
/*    width: 100%;*/
/*    min-height: calc(100vh - 171px);*/

/*    background-image:*/
/*            linear-gradient(*/
/*                    rgba(0, 0, 0, 0.61),*/
/*                    rgba(0, 0, 0, 0.61)*/
/*            ),*/
/*            url(/src/assets/images/editPageBackground.jpg);*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    background-position: center center;*/
/*}*/
/*.edit-resource .bibliographic .card {*/
/*    width: calc(100% - 100px);*/
/*    margin-bottom: 0;*/
/*}*/

/*.edit-resource .bibliographic .header {*/
/*    margin-top: 50px;*/
/*}*/

/*.edit-resource .bibliographic .form {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    margin-top: 50px;*/
/*    margin-bottom: 50px;*/
/*}*/

/*.edit-resource .bibliographic .card p{*/
/*    margin: 25px;*/
/*}*/

/*.edit-resource .bibliographic .form h3{*/
/*    border: #375a63 solid;*/
/*    border-width: 0 0 2px 0;*/
/*    padding: 0 0 1rem 0;*/
/*}*/

/*.edit-resource .bibliographic .form p{*/
/*    margin: 0;*/
/*}*/
.section .table.multicol {
    flex-direction: row !important;
    align-items: unset !important;
}

.multicol .column {
    width: 100%;
    gap: 12px;
    display: flex;
    flex-direction: column;
}