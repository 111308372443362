.description-box {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 15px;
	padding: 24px;
	margin-right: 0 !important;
}

.description-box #header {
	border-bottom: 2px solid #375a63;
	margin: 0;
	padding: 12px 0;
}

.description-box .desc {
	width: 90%;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.description-box .desc p {
	margin: 0;
}

.description-box .desc ul {
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 12px;
}