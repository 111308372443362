.home {
    animation-name: FadeIn;
    animation-duration: 0.7s;
    transition-timing-function: ease-in;
    margin: 0;
    min-height: calc(100vh - 122px);
    background-color: white;
}
.home .text h3{
    margin: 0;
    padding: 25px;
    text-align: center;
}
.home .text p{
    margin: 0;
    padding-bottom: 25px;
}

.home .image {
    background-image:
            linear-gradient(
                    rgba(0, 0, 0, 0.61),
                    rgba(0, 0, 0, 0.61)
            ),
            url(/src/assets/images/homePageBackground.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 330px;
}