.matrix .body .button-cell {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 160px;
	height: 41px;
	cursor: pointer;
	transition: all 0.3s ease;
}

.matrix .body .button-cell:hover {
	opacity: 0.7;
}
.matrix .body .button-cell.dragover {
	border: 1px solid #575757
}

.matrix .body .button-cell.pulse {
	animation: pulse 0.5s infinite;
}

.delete-menu-item span {
	color: #C63737 !important;
}