.edit-resource .experiments .experiment-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 24px);
    min-height: calc(100vh - 122px);
    gap: 24px;
    margin: 12px;
}

.edit-resource .experiments .experiment-steps #header {
    border-bottom: 2px solid #375a63;
    margin: 0;
    padding: 12px 0;
}

.edit-resource .experiments .experiment-steps .p-steps {
    width: 100%;
    padding: 24px;
}

.edit-resource .experiments .experiment-steps .p-menuitem-link {
    flex-direction: column;
}

.experiments .step-content {
    padding-top: 0 !important;
}

.edit-resource .experiments .steps-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /*min-height: calc(100vh - 197px);*/
    gap: 24px;
    margin: 0;
    /*min-height: 40vh;*/
    padding-bottom: 40px;
    width: 100%;
}