@keyframes FadeIn {
    0% {
        opacity: 0.7;
        color: transparent;
    }
    100% {
        opacity: 1;
    }
}

@keyframes FadeInFrom0 {
    0% {
        opacity: 0;
        color: transparent;
    }
    100% {
        opacity: 1;
    }
}

.p-component, .p-link, .p-dropdown-label, label {
    font-family: Montserrat;
}


p {
    font-family: Montserrat;
}
h1,h2,h3,h4,h5 {
    font-family: Lato;
}

.card {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 35px;
    margin: 12px 0;
}

@keyframes p-component-overlay-enter-animation {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(0, 0, 0, 0.32);
    }
}