/*.edit-resource .sites {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    animation-name: FadeIn;*/
/*    animation-duration: 0.7s;*/
/*    transition-timing-function: ease-in;*/
/*    margin: 0;*/
/*    gap: 50px;*/
/*    width: 100%;*/
/*    min-height: calc(100vh - 171px);*/

/*    background-image:*/
/*            linear-gradient(*/
/*                    rgba(0, 0, 0, 0.61),*/
/*                    rgba(0, 0, 0, 0.61)*/
/*            ),*/
/*            url(/src/assets/images/editPageBackground.jpg);*/
/*    background-repeat: no-repeat;*/
/*    background-size: cover;*/
/*    background-position: center center;*/
/*}*/

/*.edit-resource .sites .table {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: flex-start;*/
/*    !*min-height: calc(100vh - 197px);*!*/
/*    gap: 24px;*/
/*    margin: 0;*/
/*    min-height: 60vh;*/
/*    padding-bottom: 30px;*/
/*}*/

.edit-resource .sites #header {
    border-bottom: 2px solid #375a63;
    margin: 0;
    padding: 12px 0;
}