.footer {
    height: 51px;
    width: 100%;
    background-color: #375a63;
    margin: 0;
    /*position: absolute;*/
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.idrc-img {
    height: 47px;
}

.footer .scio-img {
    height: 48px;
}

.powered-by-scio a {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    height: 48px;
}

.powered-by-scio p {
    margin-bottom: unset;
    font-size: 1.2em;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
}